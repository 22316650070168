import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import Obj from '@/assets/img/tmp/donors/interview_obj@2x.png';
import Reviewer1 from '@/assets/img/tmp/donors/interview_reviewer1@2x.png';
import Reviewer2 from '@/assets/img/tmp/donors/interview_reviewer2@2x.png';
import Reviewer3 from '@/assets/img/tmp/donors/interview_reviewer3@2x.png';
import Sec1Obj1 from '@/assets/img/tmp/donors/interview_sec1_obj1@2x.png';
import Sec2Obj1 from '@/assets/img/tmp/donors/interview_sec2_obj1@2x.png';
import Sec2Obj2 from '@/assets/img/tmp/donors/interview_sec2_obj2@2x.png';
import AdminBanner from '@/components/AdminBanner';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import ContactUs from '@/components/ContactUs';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section, { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { graphql, PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';

const contactData = [
  {
    id: 1,
    tit: `문의`,
    costumer: `후원자관계팀`,
    tel: `02-737-1004`,
    email: `donor@unicef.or.kr`,
  },
];

const Review = styled.div`
  position: relative;

  .dec {
    text-align: justify;
    word-break: break-all;
    margin-top: 32px;
  }

  ${Tit} {
    display: block;
    line-height: 1.6;

    &.name {
      margin-top: 16px;
    }
  }

  .img-wrap {
    position: absolute;
    max-width: 352px;
    width: 100%;
    z-index: -1;
  }

  &::before {
    content: '';
    display: block;
    width: 542px;
    height: 542px;
    background: url(${Obj}) center no-repeat;
    background-size: cover;
    position: absolute;
    display: none;
    z-index: -1;
  }
  &.t1 {
    padding-top: 280px;
    padding-right: 94px;

    .img-wrap {
      top: 0;
      right: 32px;
    }

    &::before {
      display: block;
      left: -75px;
      top: -40px;
    }
  }

  &.t2 {
    padding-left: 471px;

    .img-wrap {
      top: 10px;
      left: 91px;
    }

    &::before {
      display: block;
      left: 131px;
      top: -61px;
      transform: rotate(90deg);
    }
  }

  &.t3 {
    max-width: 640px;
    &::before {
      display: block;
      left: 289px;
      top: -137px;
    }
  }

  &.t4 {
    max-width: 1024px;
    padding-left: 384px;
    margin-bottom: 240px;

    .img-wrap {
      top: 19px;
      left: 0;
    }
    &::before {
      display: block;
      left: 72px;
      top: -57px;
      transform: rotate(90deg);
    }
  }

  ${breakpoint(1160)} {
    .img-wrap {
      max-width: 30.34vw;
    }

    &::before {
      width: 46.72vw;
      height: 46.72vw;
    }
    &.t1 {
      padding-top: 24.14vw;
      padding-right: 8.1vw;

      .img-wrap {
        top: 0;
        right: 2.76vw;
      }

      &::before {
        left: -6.47vw;
        top: -3.45vw;
      }
    }

    &.t2 {
      padding-left: 40.6vw;

      .img-wrap {
        top: 0.86vw;
        left: 7.84vw;
      }

      &::before {
        left: 11.29vw;
        top: -5.26vw;
      }
    }

    &.t3 {
      max-width: 55.17vw;
      &::before {
        left: 24.91vw;
        top: -11.81vw;
      }
    }

    &.t4 {
      max-width: 1024px;
      padding-left: 33.1vw;
      margin-bottom: 20.69vw;

      .img-wrap {
        top: 1.64vw;
      }
      &::before {
        display: block;
        left: 6.21vw;
        top: -4.91vw;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    .img-wrap {
      max-width: 234px;
    }

    .dec {
      margin-top: 24px;
    }

    &::before {
      width: 359px;
      height: 359px;
    }
    &.t1 {
      padding-top: 258px;
      padding-right: 0;

      .img-wrap {
        top: 0;
        right: auto;
        left: 86px;
      }

      &::before {
        display: block;
        left: -132px;
        top: -26px;
      }
    }
    &.t2 {
      padding-left: 0;
      padding-top: 258px;

      .img-wrap {
        top: 0;
        left: 0;
      }

      &::before {
        display: block;
        left: 27px;
        top: -52px;
        transform: rotate(90deg);
      }
    }
    &.t3 {
      max-width: 100%;
      &::before {
        display: block;
        left: -132px;
        top: -94px;
      }
    }
    &.t4 {
      max-width: 100%;
      padding-left: 0;
      padding-top: 258px;
      margin-bottom: 72px;

      .img-wrap {
        top: 0;
        left: 0;
      }
    }
  }
`;
const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -16px;

  & > * {
    padding: 0 16px;
  }

  &.t1 {
    .col1 {
      width: 322px;
    }
    .col2 {
      width: calc(100% - 322px);
      max-width: 672px;
    }
  }

  &.t2 {
    position: relative;
    padding-top: 230px;

    &::before {
      content: '';
      display: block;
      width: 109px;
      height: 215px;
      background: url(${Sec1Obj1}) center no-repeat;
      background-size: cover;
      position: absolute;
      z-index: -1;
      top: 43px;
      left: 16px;
    }

    .col {
      max-width: 1060px;
    }
  }
  &.t4 {
    justify-content: flex-end;
  }

  ${breakpoint(1160)} {
    &.t2 {
      &::before {
        width: 9.4vw;
        height: 18.53vw;
        top: 3.71vw;
        left: 1.38vw;
      }

      .col {
        max-width: 1060px;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    flex-wrap: wrap;
    &.t1 {
      .col1,
      .col2 {
        width: 100%;
        max-width: 100%;
      }
      .col2 {
        margin-top: 145px;
      }
    }

    &.t2 {
      padding-top: 171px;

      &::before {
        width: 54px;
        height: 107px;
        top: 24px;
        left: 16px;
      }

      .col {
        max-width: 1060px;
      }
    }
  }
`;

const Header = styled(SectionHeader)`
  &.none-padding {
    padding-bottom: 0;
  }
  ${Tit} {
    line-height: 1.4;
  }
`;
const Section1 = styled(Section)`
  padding-bottom: 396px !important;

  ${breakpoint(`tablet`)} {
    padding-bottom: 60px !important;
  }
`;
const Section2 = styled(Section)`
  position: relative;

  &::before,
  &::after {
    content: '';
    display: block;
    max-width: 1120px;
    width: 100%;
    height: 1px;
    background: #d8d8d8;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
  }

  &::after {
    background: #d8d8d8;
    top: auto;
    bottom: 0;
  }

  ${Container} {
    position: relative;
    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
    }

    &::before {
      width: 67px;
      height: 133px;
      background: url(${Sec2Obj1}) center no-repeat;
      background-size: cover;
      top: -87px;
      right: 20px;
    }
    &::after {
      width: 134px;
      height: 252px;
      background: url(${Sec2Obj2}) center no-repeat;
      background-size: cover;
      bottom: -50px;
      right: 20px;
    }

    ${breakpoint(1160)} {
      &::before {
        width: 5.78vw;
        height: 11.47vw;
        top: -7.5vw;
        right: 1.72vw;
      }
      &::after {
        width: 11.55vw;
        height: 21.72vw;
        bottom: -4.31vw;
        right: 1.72vw;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    padding-bottom: 188px !important;
    ${Container} {
      &::before {
        width: 40px;
        height: 80px;
        top: 56px;
        right: 0;
      }
      &::after {
        width: 79px;
        height: 149px;
        bottom: -149px;
        right: 40px;
      }
    }
  }
`;

const ContactusContainer = styled.div`
  margin-top: 96px;
`;

const Interview: React.FC<PageProps> = ({ location }) => {
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '290', '300'],
        pagePerCount: 8,
        category: ['A033'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadThumbs();
  }, [loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="후원자 이야기"
      description="for every child, a donor story"
    >
      <Section className="by-sub-main-layout">
        <Container>
          <Header className="with-desc none-padding">
            <h2>
              <Tit size="s1">
                <PageComponent id="title1">
                  후원자님과의 만남을 <br />
                  이어가는 방법
                </PageComponent>
              </Tit>
            </h2>
            <p className="header-dec">
              <PageComponent id="content1">
                후원자님과 특별한 만남을 가지며 나눈 따뜻한 이야기를 소개합니다.
              </PageComponent>
            </p>
          </Header>
        </Container>
      </Section>

      <Section1 className="by-sub-main-layout">
        <Container>
          <Header className="with-desc">
            <h2>
              <Tit size="s1">
                내가 <br />
                후원을 하는 이유
              </Tit>
            </h2>
          </Header>
          <FlexContainer className="t1">
            <div className="col1">
              <Review className="t0">
                <Tit size="s3-1" color="sky">
                  일흔둘, <br />
                  다시 일하는 기쁨과 <br />
                  감사를 담아
                </Tit>
                <Tit size="s4-1" className="name">
                  - 우정미 후원자님
                </Tit>
                <p className="dec">
                  저는 작년부터 국가에서 시행하는 노인 일자리 사업에 참여해 다시
                  일하는 행복을 누리고 있습니다. 나이는 많지만 쓰임이 있다는
                  감사함에 받은 사랑을 실천할 길을 고민했답니다. 마침 며느리
                  소개로 유니세프를 알게 돼 후원한 지 어느새 1년이 됐네요. 제
                  노동의 대가로 누군가에게 힘이 되는 후원을 한다고 생각하니
                  출근길이 더욱 새롭고 행복합니다. 오늘도 이 할머니는 나눔을
                  실천할 줄 아는 직장 여성으로, 일흔둘에도 힘차게 출근합니다!
                </p>
              </Review>
            </div>
            <div className="col2">
              <Review className="t1">
                <div className="img-wrap">
                  <Image pcSrc={Reviewer1} mobileSrc={Reviewer1} />
                </div>

                <div className="dec-wrap">
                  <Tit size="s3-1" color="sky">
                    캄보디아 여행이 바꾼 <br />
                    나의 일상
                  </Tit>
                  <Tit size="s4-1" className="name">
                    - 장해용 후원자님
                  </Tit>
                  <p className="dec">
                    저는 평소 기부라는 걸 생각해 본 적이 없었어요. 부유하지 못한
                    가정에서 자랐기에 다른 사람의 불우함도 저와 큰 차이가 없다고
                    생각했거든요. 그러다 동남아 여행 중 생각이 달라졌습니다.
                    그때 캄보디아에서 학교도 가지 못한 채 물건을 팔며 생계를
                    책임지는 아이들을 보고 마음이 편치 못했어요. 한국에 돌아와
                    갓 태어난 조카를 볼 때마다 캄보디아 아이들이 생각났어요.
                    단지 다른 나라, 다른 환경에서 태어났다는 이유만으로 한창
                    사랑받고 공부할 시간에 일을 한다고 생각하니 안타까웠습니다.
                    그때 유니세프 정기후원을 시작해 호프링을 받았습니다. 평소
                    반지를 잘 끼지 않지만 일을 할 땐 끼려고 노력했어요. 일하다
                    피곤하고 힘들 땐 호프링을 보면서 나의 노력, 나의 월급 중
                    일부가 아이들에게 간다는 생각을 하고 다시 일할 힘을 얻곤
                    합니다. 이제는 다른 사람에게 기부를 권하고 다닐 정도로
                    만족감도 커요. 앞으로도 유니세프가 전 세계 아이들을 위해
                    많은 노력을 해주었으면 해요.
                  </p>
                </div>
              </Review>
            </div>
          </FlexContainer>
          <FlexContainer className="t2">
            <div className="col">
              <Review className="t2">
                <div className="img-wrap">
                  <Image pcSrc={Reviewer2} mobileSrc={Reviewer2} />
                </div>
                <Tit size="s3-1" color="sky">
                  귀농생활의 씨앗이자 열매가 된 기부
                </Tit>
                <Tit size="s4-1" className="name">
                  - 유현수 후원자님
                </Tit>
                <p className="dec">
                  귀농한 지 4년 되는 청년 농부입니다. 저는 귀농을 하며 첫 기부를
                  시작했습니다. 수입이 없는 상태였지만 ‘음료수 한 캔 덜먹으면
                  되지’라는 마음으로 행복하게 이어왔습니다. 그리고 요즘 저는
                  유니세프 기부가 제 귀농생활의 버팀목임을 깨닫습니다. 기부를
                  하면서 제 마음가짐과 삶의 방식이 조금씩 바뀌었거든요. 유니세프
                  기부는 제 귀농살이의 첫 씨앗임과 동시에 커다란 기쁨으로 다가온
                  열매라는 걸 새삼 느낍니다.
                </p>
              </Review>
            </div>
          </FlexContainer>
        </Container>
      </Section1>

      <Section2 className="by-sub-main-layout">
        <Container>
          <Header className="with-desc">
            <h2>
              <Tit size="s1">
                나눔 후 <br />
                달라진 것들
              </Tit>
            </h2>
          </Header>
          <FlexContainer className="t3">
            <div className="col">
              <Review className="t3">
                <Tit size="s3-1" color="sky">
                  ‘아버지를 향한 그리움’에서 <br />
                  ‘아빠가 된 기쁨이자 책무’로 변한 후원
                </Tit>
                <Tit size="s4-1" className="name">
                  - 전○○ 후원자님
                </Tit>
                <p className="dec">
                  내가 유니세프 회원이 된 건 아버지 덕분이다. 1993년 아버지가
                  돌아가시고 몇 달 뒤 유니세프 지로용지를 받았다. 평생
                  대형트럭을 운전하시며 도로 위에서 지내신 분인데 언제 이런 걸
                  하셨는지… 아버지 뜻을 이었으면 좋겠다는 어머니 말씀에 따라
                  후원자명을 내 이름으로 바꿔서 지금까지 왔다. <br />
                  결혼을 하고 아들이 생겼는데 태어나면서부터 심장이 약해 애를
                  먹은 뒤로는 소액이지만 심장재단에도 후원을 시작했다. IMF사태로
                  실직과 취업이 반복돼 몇 년간 경제적 어려움을 겪으며 후원을
                  중단할까 생각도 했다. 하지만 아들 때문에 마음 고생한 일도
                  있었던 터라 중단되는 일은 없도록 아예 자동이체로 바꿔버렸다.
                  그리고는 후원한다는 것조차 잊고 산다. 그러다가 종종 떠오르면
                  주변에 유니세프 회원이 되라고 권하기도 한다. <br />
                  ‘아버지를 향한 그리움’으로 시작한 후원이 ‘아들을 얻은 기쁨이자
                  책무’였다가 이제 ‘언제 인출됐는지도 모르는 일상’이 됐다.
                  작년인가 후원 25주년 감사장을 받았다. 새삼 내 나이를 실감했다.
                  지난 시간을 기억해 준 유니세프에게, 내 나이를 실감하게 해 준
                  유니세프에게 고맙다는 말을 전한다.^.^
                </p>
              </Review>
            </div>
          </FlexContainer>
        </Container>
      </Section2>

      <Section className="by-sub-main-layout">
        <Container>
          <Header className="with-desc">
            <h2>
              <Tit size="s1">
                유니세프에 후원한 <br />
                보람을 느낄 때
              </Tit>
            </h2>
          </Header>
          <FlexContainer className="t4">
            <div className="col">
              <Review className="t4">
                <div className="img-wrap">
                  <Image pcSrc={Reviewer3} mobileSrc={Reviewer3} />
                </div>

                <Tit size="s3-1" color="sky">
                  ‘BTS’로 시작한 후원, <br />
                  후원금 쓰인 곳 알게 될 때 행복해져
                </Tit>
                <Tit size="s4-1" className="name">
                  - 김선미 후원자님
                </Tit>
                <p className="dec">
                  BTS의 선한 영향력에 끌려 유니세프 후원을 시작했습니다.
                  유니세프가 하는 일 가운데서도 폭력으로부터 아이들을 지키고
                  보호하는 일에 제 작은 나눔이 도움이 될까 해 기꺼이 후원을 하게
                  됐습니다. 우리나라에서도 가정폭력이나 아동학대가 종종 발견되듯
                  지금도 세계 어딘가에서는 폭력 때문에 고통받는 아이들이 많을
                  것이라 생각합니다. 이 아이들에게 제 작은 도움의 손길이 따뜻한
                  행복감을 느끼게 해 줄 수 있기를 바랄 뿐입니다. <br />
                  후원을 시작한 뒤 가장 기쁜 순간은 제가 후원한 돈이 어디에
                  어떻게 쓰였다는 알림이 올 때입니다. 후원금이 잘 쓰이고 있구나
                  하는 생각이 들거든요. 전 세계 아이들이 폭력으로 벗어나 안전한
                  공간에서 보호받고 몸도 마음도 건강하게 생활할 수 있다면 그게
                  제일 행복할 것 같습니다.
                </p>
              </Review>
            </div>
          </FlexContainer>
          <div className="bn-item">
            <AdminBanner idx="1" kind="0004" />
          </div>

          <ContactusContainer>
            <ContactUs itemData={contactData} />
          </ContactusContainer>
        </Container>
      </Section>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A033" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default Interview;
